<template>
  <div class="modal modal-center">
    <div class="modal-bg" @click="$emit('close')"></div>
    <div class="modal-block ">
      <div class="modal-header">
        <div>
          <h3>
            Time Details
          </h3>
          <div class="sub-title">
            *activities = when user updates the page or opens new one etc.
          </div>
        </div>
      </div>
      <div class="modal-body">
        <div class="text-center mt-5 mb-5" v-if="ui.loading === true">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div class="modal-body-container border-0 mt-3" v-if="ui.loading === false" id="time-details">
          <div class="table">
            <table class="table">
              <thead>
                <tr>
                  <th>From</th>
                  <th>To</th>
                  <th>Time</th>
                  <th>Activity log</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input type="date" class="date-input">
                    <input type="time" class="input-time">
                  </td>
                  <td>
                    <input type="date" class="date-input">
                    <input type="time" class="input-time">
                  </td>
                  <td>
                    1 hrs 1 m
                  </td>
                  <td>No Activity</td>
                  <td>
                    <button class="btn btn-control">
                      <img src="@/assets/icons/icon-delete.svg" alt="Icon Delete">
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-start">
        <button class="btn bg-green">
          Save Changes
        </button>
        <button class="btn btn-cancel">
          Close
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import PerfectScrollbar from 'perfect-scrollbar';

export default {
  name: 'TimeDetailsModal',
  data() {
    return {
      ui: {
        loading: false
      }
    }
  },
  methods: {
    getData() {
      this.ui.loading = true;

      setTimeout(() => {
        this.ui.loading = false;
      }, 1000)
    }
  },
  beforeMount() {
    this.getData();
  },
  mounted() {
    setTimeout(() => {
      const scrollbar = document.getElementById('time-details');
      new PerfectScrollbar(scrollbar)
    }, 1200)
  }
}
</script>

<style lang="scss" scoped>
.input-time {
  width: 25%;
}
.date-input {
  width: 65%;
  margin-right: 10px;
}
.modal-block {
  width: 992px;
  height: fit-content!important;
  margin: 40px auto!important;
}
.modal-footer {
  bottom: auto;
}
.modal-body {
  padding: 100px 30px 0 30px;
}
table {
  tr {
    vertical-align: middle;
  }
}
#time-details {
  position: relative;
  overflow: hidden;
  height: 400px;
}
</style>
