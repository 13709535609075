<template>
  <div>
    <div class="page-header d-flex justify-content-between align-items-center">
      <div>
        <h3 class="page-header-title">
          Time Tracking
        </h3>
      </div>
      <button class="btn btn-primary" @click="generateReport">
                <span v-if="ui.generating"
                      class="spinner-border spinner-border-sm"
                      role="status" aria-hidden="true">
          </span>
        <span v-if="!ui.generating">Generate Report</span>
      </button>
    </div>
    <div class="page-content">

      <div class="row">
        <div class="col-6">
          <div class="search-wrapper">
            <img src="@/assets/icons/icon-search.svg" alt="Icon search">
            <input type="text" placeholder="Search" v-model="searchField">
          </div>
        </div>
        <div class="col-6 text-end">
          <date-range-picker
            ref="picker"
            v-model="dateRange"
            class="daterange-dashboard"
            opens="left"
            :auto-apply="true"
            :timePicker="false"
            :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY HH:mm:ss' }"
          >

            <template v-slot:input="picker">
              {{ picker.startDate | date }} - {{ picker.endDate | date }}
              <img src="@/assets/icons/icon-calender.svg" class="position-absolute" alt="">
            </template>
          </date-range-picker>
        </div>
      </div>
      <div class="text-center mt-4" v-if="ui.loading === true">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div class="table" v-if="ui.loading === false">
        <table class="table">
          <thead>
            <tr>
              <th>Employee</th>
              <th>Regular Hours</th>
              <th>Overtime</th>
              <th>Double Overtime</th>
              <th>Total Hours Tracked</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Aya Albahnasi</td>
              <td>0 hrs 0 m</td>
              <td>0 hrs 0 m</td>
              <td>0 hrs 0 m</td>
              <td>0 hrs 0 m</td>
              <td>
                <button class="btn btn-primary w-50" @click="modals.timeDetails = true">
                  Details
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <transition name="component-fade" mode="out-in">
      <time-details-modal v-if="modals.timeDetails === true"
                          @close="modals.timeDetails = false" />
    </transition>
  </div>
</template>

<script>
import moment from 'moment';
import DateRangePicker from 'vue2-daterange-picker';
import TimeDetailsModal from "./modals/TimeDetails";

export default {
  name: 'Timer',
  components: { TimeDetailsModal, DateRangePicker },
  data() {
    return {
      searchField: '',
      dateRange: {
        startDate: '2019-12-10',
        endDate: '2019-05-02'
      },
      modals: {
        timeDetails: false
      },
      ui: {
        loading: false,
        generating: false
      }
    }
  },
  methods: {
    getData() {
      this.ui.loading = true;

      setTimeout(() => {
        this.ui.loading = false;
      }, 1000)
    },
    generateReport() {
      this.ui.generating = true;

      setTimeout(() => {
        this.ui.generating = false;
      }, 1000)
    }
  },
  created() {
    this.getData();
  },
  filters: {
    date(date) {
      return moment(String(date)).format('MM/DD/YYYY')
    }
  }
}
</script>

<style lang="scss" scoped>
table.table {
  tbody {
    tr {
      vertical-align: middle;
    }
  }
}
</style>
